import React, { useEffect, useState } from "react";
import "./ReviewForm.component.css";
import {
  B_1099,
  DIV_1099,
  FORM1099_B,
  FORM1099_DIV,
  FORM1099_INT,
  FORM1099_K,
  FORM1099_MISC,
  FORM1099_NEC,
  FORM1099_S,
  INT_1099,
  K_1099,
  MISC_1099,
  NEC_1099,
  S_1099,
} from "../../../lib/constants";
import {
  reviewFormError,
  reviewFormLabels,
  stateInfoDisplayTexts,
} from "../../../lib/languagePacks/en-us";
import SecondTinNotice from "../GlobalFormComponents/InternalComponents/SecondTinNotice/SecondTinNotice";
import NecRightPortion from "./NECRightPortion/NecRightPortion";
import AccountNumber from "./RFAccount/AccountNumber/AccountNumber";
import RFAccount from "./RFAccount/RFAccount";
import RFPayerDetails from "./RFPayerDetails/RFPayerDetails";
import RFPayerTin from "./RFPayerTin/RFPayerTin";
import RFRecLocation from "./RFRecLocation/RFRecLocation";
import RFRecName from "./RFRecName/RFRecName";
import RFRecTin from "./RFRecTin/RFRecTin";
import RFStreetAddress from "./RFStreetAddress/RFStreetAddress";
import EditNextBtn from "./Edit-Next/EditNextBtn";
import MiscRightPortion from "./MiscRightPortion/MiscRightPortion";
import Fatca from "../GlobalFormComponents/FATCA/Fatca";
import IntRightPortion from "./INTRightPortion/IntRightPortion";
import DivRightPortion from "./DIVRightPortion/DivRightPortion";
import SRightPortion from "./Form1099SRightPortion/SRightPortion";
import StateWithoutIncomeField from "./RFState/StateWithoutIcomeField/StateWithoutIncomeField";
import BRightPortion from "./BRightPortion/BRightPortion";
import KRightPortion from "./KRightPortion/KRightPortion";
import FilerPayment from "../FilerPayment/FilerPayment";
import PseDetails from "../PseDetails/PseDetails";
import devLogInstance from "../../../lib/loggerConfig";
import ombAndRev from "../../../lib/json/OMBandRev.json";
import fs from "fs";
import { useSelector } from "react-redux";

const formsWithReverseFatca = [FORM1099_B];
export const formsWithStateFilingOnleft = [FORM1099_B];
const formsWithTinOnRight = [FORM1099_K];
const formsWithoutFatca = [FORM1099_K];
// const formsWithNoStateIncomeField = [FORM1099_B, FORM1099_DIV, FORM1099_INT];

export default function ReviewForm({ formType, taxYear, formData, recDataUrl }: any) {
  const formTypes = useSelector((state: any) => state.formTypes);
  devLogInstance.log(formType);
  return (
    <div className="rf-main">
      {formTypes?.length === 0 ? (
        <div className="h-full w-full flex justify-center items-center">
          <h2 className="opacity-40"> {reviewFormError.no_form_found} </h2>
        </div>
      ) : (
        <>
          <div className="rf-wrapper-container">
            <div className="rf-wrapper h-full">
              <iframe
                title="Review Copy"
                src={`${recDataUrl}#zoom=141`}
                className="h-full w-full"
              />
            </div>
          </div>
          <EditNextBtn />
        </>
      )}
    </div>
  );
}
