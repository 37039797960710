import logger from "../../logger";
import { SET_PAYER_NAMES, TO_START_LOADER } from "../../redux/actions/action.types";
import setTotalCartItems from "../Cart/setCartItems";
import setTotalRPItems from "../ReviewPending/setTotalRPItems";
import redirect from "../RoutingMethods/redirect";
import setNonReviewedData from "../StateSetters/setNonReviewedData";
import setTruthyCartValues from "../StateSetters/setTruthyCartValues";
import setDraftData from "../UserAccount/setDraftData";
import getRefreshToken from "../authRequests/getRefreshToken";
import logoutUser from "../authRequests/logoutUser";
import retrieveToken from "../authRequests/retrieveTokens";
import saveTokens from "../authRequests/saveTokens";
import deleteForms from "../backendData/deleteForms";
import editForm from "../backendData/editForm";
import formReview from "../backendData/formReview";
import formUnreview from "../backendData/formUnreview";
import getCartInfo from "../backendData/getCartInfo";
import getHomeData from "../backendData/getHomeData";
import getIrscopies from "../backendData/getIrsCopies";
import { getPayerNames } from "../backendData/getPayerData";
import getSelectedNonReviewedData from "../backendData/getSelectedNonReviewedData";
import getTemplate from "../backendData/getTemplate";
import updateCartCheckbox from "../backendData/updateCartCheckbox";
import uploadFile, {
  uploadBusinessTemplate,
  uploadRecipientTemplate,
} from "../backendData/uploadFile";
import {
  ADMIN_ACCOUNT,
  ERROR_POPUP,
  NO_SUPPORT_HEADING,
  ONLINE_ACCESS,
  ONLINE_ACCESS_PDF_PREVIEW,
  PAYER_COPY,
  POSTAL_MAILING,
  RECIPIENT_COPY,
  REVIEW_PAGE_EFORM,
  STATE_COPY,
  STATE_FILING,
  SUCCESS_POPUP,
  TEMPLATE_DOWNLOAD,
  USER_ACCOUNT,
} from "../constants";
import {
  enquiryFloaterSuccessMessage,
  noAsciiFileMsg,
  noSupportWV,
  serverErrorMsg,
  toastMessage,
} from "../languagePacks/en-us";
import devLogInstance, { prodLogInstance } from "../loggerConfig";
import handlePopup from "../popup/handlePopup";
import CartValueArray from "../utils/CartValues";
import DisableScreen from "../utils/disableScreen";
import removeTruthyCartValues from "../StateSetters/removeTruthyCartValues";
import payment from "../backendData/payment";
import getPrices from "../backendData/getPrices";
import setRecipientsWithoutEmailId from "../StateSetters/setRecipientsWithoutEmailId";
import removeRecFromNoEmailArray from "../StateSetters/removeRecFromNoEmailArray";
import updateonlineAccessEmails from "../backendData/updateOnlineAccessEmails";
import setDataToEdit from "../StateSetters/setDataToEdit";
import updateForm from "../backendData/updateForm";
import retrieveReviewPageType from "../RetrieveDataLocally/retrieveReviewPageType";
import getVerificationCode from "../backendData/getVerificationCode";
import changeEmail from "../backendData/changeEmail";
import changePassword from "../backendData/changePassword";
import changePhonenum from "../backendData/changePhonenum";
import changeFullname from "../backendData/changeFullname";
import getUserDetailsLocally from "../RetrieveUserDetailsLocally/getUserDetailsLocally";
import saveUserDetails from "../SaveData/saveUserDetails";
import submitFeedback from "../backendData/submitFeedback";
import getAllHistoryData from "../backendData/getAllHistoryData";
import getSpecificHistoryData from "../backendData/getSpecificHistoryData";
import { calCurrentTaxYear } from "../calculateTaxYear";
import {
  AdminCouponData,
  AdminPriceData,
  ConsentDataResponse,
  ContactUsData,
  EnquiryData,
  GetStaffResponse,
  GroupRecord,
  NewAdminData,
  OnlineAccessLoginData,
  PasswordChangeData,
  PasswordResetData,
  PayerData,
  RecipientLoginResponse,
  SetTransmissionStatusBody,
  StaffRecord,
  SwitchToStaffRes,
  TransactionDetailsResponse,
  ViewAdminsData,
} from "../../../interfaces/main";
import addAdmin from "../AdminRelatedUtils/addAdmin";
import deleteAdmin from "../AdminRelatedUtils/deleteAdmin";
import logoutAdmin from "../AdminRelatedUtils/logoutAdmin";
import getAdminPrices from "../AdminRelatedUtils/getAdminPrices";
import editAdminPrice from "../AdminRelatedUtils/editAdminPrice";
import getAdminCoupons from "../AdminRelatedUtils/getAdminCoupons";
import addCoupon from "../AdminRelatedUtils/addCoupon";
import deleteCoupon from "../AdminRelatedUtils/deleteCoupon";
import getAdmins from "../AdminRelatedUtils/getAdmins";
import changeAdminPassword from "../AdminRelatedUtils/changeAdminPassword";
import contactUs from "../backendData/contactUs";
import sendPasswordResetVerifyCode from "../backendData/PasswordReset/sendPasswordResetVerifyCode";
import resetPassword from "../backendData/PasswordReset/resetPassword";
import { E_FILING, HOME, REVIEW } from "../routes";
import recipientLogin from "../backendData/OnlineAccessPortalRelated/recipientLogin";
import loginRecipient from "../OnlineAccessRelatedUtils/loginRecipient";
import getConsentPageData from "../backendData/OnlineAccessPortalRelated/getConsentPageData";
import logoutRecipient from "../OnlineAccessRelatedUtils/logoutRecipient";
import submitConsentData from "../backendData/OnlineAccessPortalRelated/submitConsentData";
import getPayers from "../backendData/CorrectionRelated/getPayers";
import { ga4 } from "../../App";
import ga4Const from "../json/AnalyticsRelated/analyticsConst.json";
import getAsciiFile from "../AdminRelatedUtils/getAsciiFile";
import { getTheFileName } from "../../components/forms/ReviewTable/BulkAction/BulkAction";
import { Download } from "../utils/Download";
import setTransmissionStatus from "../AdminRelatedUtils/setTransmissionStatus";
import getFormCorrectionSummary from "../backendData/CorrectionRelated/getFormCorrectionSummary";
import getFormCorrectionData from "../backendData/CorrectionRelated/getFormCorrectionData";
import submitCorrectedData from "../backendData/CorrectionRelated/submitCorrectedData";
import getPayerCopies from "../backendData/FormDownload/getPayerCopies";
import getRecCopies from "../backendData/FormDownload/getRecipientCopies";
import { NavigateFunction } from "react-router-dom";
import getOnlineAccessFormData from "../backendData/OnlineAccessPortalRelated/getOnlineAcessFormData";
import getOnlineAccessRecCopy from "../backendData/OnlineAccessPortalRelated/getOnlineAccessRecCopy";
import getStateCopies from "../backendData/FormDownload/getStateCopies";
import activateEmail from "../backendData/VerificationRelated/activateEmail";
import displayInfoModal from "../StateSetters/displayInfoModal";
import { formFieldsValidator } from "../validators";
import { axiosInstance } from "../axiosConfig";
import { getStaff } from "../../components/BusinessSettingsRelated/ManageStaff";
import { getGroup } from "../../components/BusinessSettingsRelated/ManageGroup";
import displayAddBulkStaffModal from "../StateSetters/StaffAndGroupRelated/displayAddBulkStaffModal";
import displayBulkCreateGroupModal from "../StateSetters/StaffAndGroupRelated/displayBulkCreateGroupModal";
import downloadDocument from "../AdminRelatedUtils/downloadTransmittedForm";

export class Main {
  static stateDispatch: any = () => {};
  private authErrorFlag: number = 0;

  handleHomeDataProcessing(stateHandler: any) {
    // Get the home data
    getHomeData()
      .then((res: any) => {
        logger.log(res);
        // Set the total cart items
        setTotalCartItems(stateHandler, res.totalCartItems);
        // Set the draft data
        setDraftData(stateHandler, res.DraftData);
        // Set total review pending items
        setTotalRPItems(stateHandler, res.reviewPendingItems);
      })
      .catch(async (err: any) => {
        console.log(err);
        await this.handleError(err, stateHandler, () => {
          this.handleHomeDataProcessing(stateHandler);
        });
      });
  }

  handlePayerNameProcessing(stateHandler: any) {
    // Start the loader
    stateHandler({
      type: TO_START_LOADER,
      payload: true,
    });
    // Get the payer names
    getPayerNames()
      .then((res: any) => {
        logger.log(res);
        // Set the payer names
        stateHandler({
          type: SET_PAYER_NAMES,
          payload: res.payer_names,
        });
        // Stop the loader
        stateHandler({
          type: TO_START_LOADER,
          payload: false,
        });
      })
      .catch(async (err: any) => {
        await this.handleError(err, stateHandler, () => {
          this.handlePayerNameProcessing(stateHandler);
        });
      });
  }

  handleGettingSelectedNRF(stateHandler: any, cartIds: any, year: number) {
    // Start the loader
    stateHandler({
      type: TO_START_LOADER,
      payload: true,
    });
    // Get the non-reviewed forms
    getSelectedNonReviewedData(cartIds, year)
      .then((res: any) => {
        devLogInstance.log(res);
        if (res.NonReviewedData.length !== 0) {
          // Update the non-reviewed data state
          setNonReviewedData(stateHandler, res.NonReviewedData);
        } else {
          // Redirect to the home page
          stateHandler({
            type: TO_START_LOADER,
            payload: false,
          });
          // redirect(`${HOME}`);
        }
        // // Stop the loader
      })
      .catch(async (err: any) => {
        await this.handleError(err, stateHandler, () => {
          this.handleGettingSelectedNRF(stateHandler, cartIds, year);
        });
      });
  }

  async handleFormReview(cartIds: any, stateHandler: any) {
    DisableScreen.enable();
    try {
      const res = await formReview(cartIds);
      DisableScreen.disable();

      // Custom GA4 analytics event
      ga4.sendEvent({
        category: ga4Const.event.category["e-filing"],
        action: ga4Const.event.action.formReview,
      });

      return res;
    } catch (err: any) {
      // Custom GA4 analytics event
      ga4.sendEvent({
        category: ga4Const.event.category["e-filing"],
        action: ga4Const.event.action.formReviewFail,
      });

      await this.handleError(err, stateHandler, () => {
        DisableScreen.disable();
        this.handleFormReview(cartIds, stateHandler);
      });
    }
  }

  async handleFormUnreview(cartIds: any, stateHandler: any) {
    DisableScreen.enable();
    try {
      const res = await formUnreview(cartIds);
      DisableScreen.disable();
      return res;
    } catch (err: any) {
      await this.handleError(err, stateHandler, () => {
        DisableScreen.disable();
        this.handleFormUnreview(cartIds, stateHandler);
      });
    }
  }

  async handleDownload(cartIds: any, stateHandler: any, downloadOptions: any = null) {
    DisableScreen.enable();
    try {
      devLogInstance.log(cartIds);
      if (downloadOptions !== null && downloadOptions.type === TEMPLATE_DOWNLOAD) {
        const data = await getTemplate(
          downloadOptions.format,
          downloadOptions.payerId,
          downloadOptions.formType
        );
        DisableScreen.disable();
        return data;
      } else if (downloadOptions !== null && downloadOptions.type === PAYER_COPY) {
        const data = await getPayerCopies(cartIds);
        DisableScreen.disable();
        return data;
      } else if (downloadOptions !== null && downloadOptions.type === RECIPIENT_COPY) {
        const data = await getRecCopies(cartIds);
        DisableScreen.disable();
        return data;
      } else if (downloadOptions !== null && downloadOptions.type === STATE_COPY) {
        const data = await getStateCopies(cartIds);
        DisableScreen.disable();
        return data;
      } else if (downloadOptions !== null && downloadOptions.type === ONLINE_ACCESS_PDF_PREVIEW) {
        const resObj = await getOnlineAccessRecCopy();
        console.log(resObj);
        DisableScreen.disable();
        return resObj;
      }
      const data = await getIrscopies(cartIds);
      DisableScreen.disable();
      return data;
    } catch (err: any) {
      prodLogInstance.error(err);
      await this.handleError(err, stateHandler, () => {
        DisableScreen.disable();
        this.handleDownload(cartIds, stateHandler, downloadOptions);
      });
    }
  }

  async handleFormDelete(cartIds: any, stateHandler: any) {
    DisableScreen.enable();
    try {
      devLogInstance.log(cartIds);
      const data = await deleteForms(cartIds);
      DisableScreen.disable();
      return data;
    } catch (err: any) {
      prodLogInstance.error(err);
      await this.handleError(err, stateHandler, () => {
        DisableScreen.disable();
        this.handleFormDelete(cartIds, stateHandler);
      });
    }
  }

  async handleFormEdit(cartId: any, stateHandler: any, navigate: any, reviewPageType?: any) {
    DisableScreen.enable();
    try {
      // Get the payer-specific form data from the backend to update the global form data
      const { form_data } = await editForm(cartId);
      // Disable the screen disabler
      DisableScreen.disable();
      logger.log(form_data);
      // Update the editable data redux state
      setDataToEdit(form_data, stateHandler);
      // Redirect to E-Filing page
      navigate(`${E_FILING}/${form_data.form_type}/${form_data.year}?edit=1`);
    } catch (err: any) {
      // Handle error
      console.error(err);
      await this.handleError(err, stateHandler, () => {
        DisableScreen.disable();
        this.handleFormEdit(cartId, stateHandler, navigate, reviewPageType);
      });
    }
  }

  async handleFileUpload(file: any, stateHandler: any, fileUploadOptions: any, year: number) {
    try {
      switch (fileUploadOptions.formType) {
        case "BUSINESS":
          // Upload business adding template.
          const businessUploadData = await uploadBusinessTemplate(file, fileUploadOptions.format);
          return businessUploadData;

        case "RECIPIENT":
          // Upload business adding template.
          const recipientUploadData = await uploadRecipientTemplate(file, fileUploadOptions.format);
          return recipientUploadData;

        case "STAFF":
          // close the add bulk member modal
          displayAddBulkStaffModal(
            {
              toDisplay: false,
              updateStaffRecords: null,
            },
            Main.stateDispatch
          );
          // Upload the staff template
          const uploadStaffTemplateRes = await axiosInstance.post(
            "/enterprise/non_staff_operation/submit_staff_xl_template",
            {
              staff_template: file,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          return uploadStaffTemplateRes.data;

        case "GROUP":
          // close the modal
          displayBulkCreateGroupModal(
            {
              toDisplay: false,
            },
            Main.stateDispatch
          );
          // Upload the group template
          const uploadGroupTemplateRes = await axiosInstance.post(
            "/enterprise/non_staff_operation/submit_group_xl_template",
            {
              group_template: file,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          return uploadGroupTemplateRes.data;

        default:
          // Upload the form template by default.
          const data = await uploadFile(
            file,
            fileUploadOptions.format,
            fileUploadOptions.payerId,
            fileUploadOptions.formType,
            year
          );
          return data;
      }
    } catch (err: any) {
      devLogInstance.error(err);
      if (err.response.status === 403) {
        DisableScreen.disable();
        // Display the SNS modal
        displayInfoModal(
          true,
          err.response.data.Error ?? "File upload blocked!",
          NO_SUPPORT_HEADING,
          stateHandler
        );
        // Dont read rest of the code
        throw err;
      } else if (err.response?.data.Error.includes("Invalid")) {
        DisableScreen.disable();
        // Dont read rest of the code
        throw err.response.data.ErrorMessage;
      }

      await this.handleError(err, stateHandler, () => {
        this.handleFileUpload(file, stateHandler, fileUploadOptions, year);
      });
      throw err;
    }
  }

  async handleGetCartData(year: number) {
    try {
      const data = await getCartInfo(year);
      return data;
    } catch (err: any) {
      devLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        this.handleGetCartData(year);
      });
    }
  }

  async callCartCheckBoxUpdateAPI(stateHandler: any, updateOptions: any) {
    DisableScreen.enable();
    try {
      // Call the API
      const data = await updateCartCheckbox(updateOptions);
      devLogInstance.log(data);
      DisableScreen.disable();
      return data;
    } catch (err: any) {
      prodLogInstance.error(err);
      await this.handleError(err, stateHandler, () => {
        DisableScreen.disable();
        this.callCartCheckBoxUpdateAPI(stateHandler, updateOptions);
      });
    }
  }

  async handleCartCheckboxUpdate(
    e: any,
    truthyCartValues: any,
    isChecked: any,
    cartIds: any,
    payerId: any,
    stateHandler: any,
    stateFilingOptions?: any
  ) {
    const { id: cartValType } = e.target.parentElement;
    let valObj = null;
    devLogInstance.log(cartIds.length);
    CartValueArray.cartValues = truthyCartValues;
    switch (cartValType) {
      case POSTAL_MAILING:
        console.log(`CART IDS: `);
        console.log(cartIds);
        const updateOptionsPM = {
          checkbox_type: 1,
          cart_id_array: cartIds,
          checked: isChecked,
        };
        const pmUpdateRes = await this.callCartCheckBoxUpdateAPI(stateHandler, updateOptionsPM);
        if (pmUpdateRes.successfulCartIds.length !== 0) {
          if (cartIds.length === 1) {
            valObj = {
              cartValType: cartValType,
              cartId: cartIds[0],
              payerId: payerId,
              occurence: 1,
            };
          }
          if (!isChecked) {
            devLogInstance.log(valObj);
            valObj !== null && removeTruthyCartValues(valObj, stateHandler);

            // Display disable/enable toast message
            handlePopup(toastMessage.postalMailing.disabled, SUCCESS_POPUP, stateHandler);
          } else {
            valObj !== null && setTruthyCartValues(valObj, stateHandler);

            // Display enable/disable toast message
            handlePopup(toastMessage.postalMailing.enabled, SUCCESS_POPUP, stateHandler);
          }
        } else {
          //window.location.reload();
        }
        break;

      case ONLINE_ACCESS:
        const updateOptionsOA = {
          checkbox_type: 2,
          cart_id_array: cartIds,
          checked: isChecked,
        };
        const oaUpdateRes = await this.callCartCheckBoxUpdateAPI(stateHandler, updateOptionsOA);
        devLogInstance.log(oaUpdateRes);
        if (oaUpdateRes.successfulCartIds.length !== 0) {
          if (cartIds.length === 1) {
            valObj = {
              cartValType: cartValType,
              cartId: cartIds[0],
              payerId: payerId,
              occurence: 1,
            };
          }
          if (!isChecked) {
            devLogInstance.log(valObj);
            valObj !== null && removeTruthyCartValues(valObj, stateHandler);

            // Display disable toast message
            handlePopup(toastMessage.onlineAccess.disabled, SUCCESS_POPUP, stateHandler);
          } else {
            valObj !== null && setTruthyCartValues(valObj, stateHandler);

            // Display enable toast message
            handlePopup(toastMessage.onlineAccess.enabled, SUCCESS_POPUP, stateHandler);
          }
        } else {
          // window.location.reload();
        }
        break;

      case STATE_FILING:
        console.log("CALLED STATE FILING");
        const updateOptionsSF = {
          checkbox_type: 0,
          cart_id_array: cartIds,
          checked: isChecked,
        };
        const sfUpdateRes = await this.callCartCheckBoxUpdateAPI(stateHandler, updateOptionsSF);
        if (sfUpdateRes.successfulCartIds.length !== 0) {
          // const state1 = recipientData.state1
          // const state2 = recipientData.state2
          if (stateFilingOptions) {
            const { state1, state2 } = stateFilingOptions;
            if (cartIds.length === 1) {
              if (state1 && state2) {
                valObj = {
                  cartValType: cartValType,
                  cartId: cartIds[0],
                  payerId: payerId,
                  occurence: 2,
                };
              } else if (state1 || state2) {
                valObj = {
                  cartValType: cartValType,
                  cartId: cartIds[0],
                  payerId: payerId,
                  occurence: 1,
                };
              }
            }
          }

          if (!isChecked) {
            devLogInstance.log(valObj);
            valObj !== null && removeTruthyCartValues(valObj, stateHandler);

            // Display disable toast message
            handlePopup(toastMessage.stateFiling.disabled, SUCCESS_POPUP, stateHandler);
          } else {
            valObj !== null && setTruthyCartValues(valObj, stateHandler);

            // Display enable toast message
            handlePopup(toastMessage.stateFiling.enabled, SUCCESS_POPUP, stateHandler);
          }
        } else {
          window.location.reload();
          devLogInstance.log(sfUpdateRes);
        }
        break;

      default:
        break;
    }
  }

  async handleOrderCompletion(stateHandler: any, cartIds: any, couponCode: any = "") {
    DisableScreen.enable();
    try {
      const { url } = await payment(cartIds, couponCode);
      devLogInstance.log(url);
      DisableScreen.disable();
      // Custom GA4 analytics event
      ga4.sendEvent({
        category: ga4Const.event.category["e-filing"],
        action: ga4Const.event.action.payment,
        label: "Order completion",
      });
      // Redirect user to the URL
      redirect(url);
    } catch (err: any) {
      // Custom GA4 analytics event
      ga4.sendEvent({
        category: ga4Const.event.category["e-filing"],
        action: ga4Const.event.action.paymentFail,
        label: "Order completion",
      });

      prodLogInstance.error(err);
      await this.handleError(err, stateHandler, () => {
        DisableScreen.disable();
        this.handleOrderCompletion(stateHandler, cartIds, couponCode);
      });
    }
  }

  async handleGettingLatestPrices() {
    try {
      const { priceData } = await getPrices();
      devLogInstance.log(priceData);
      const filteredPriceData = priceData.reduce((obj: any) => {
        return obj.form_type === "1099";
      }); // filter the price data array based on the form type

      // Return the required fields to calculate the total price
      return [
        filteredPriceData.federal_price,
        filteredPriceData.state_price,
        filteredPriceData.postal_mailing_price,
        filteredPriceData.online_access_price,
        filteredPriceData.no_of_forms,
      ];
    } catch (err: any) {
      prodLogInstance.error(err);
      this.handleError(err, Main.stateDispatch, () => {
        return;
      });
    }
  }

  // Handle online access email update
  async handleOnlineAccessEmailUpdate(stateHandler: any, newEmails: any) {
    try {
      const data = await updateonlineAccessEmails(newEmails);
      devLogInstance.log(data);
      if (newEmails.length > 1) {
        // Empty the recipients without an email id array state
        setRecipientsWithoutEmailId(stateHandler, []);
      } else if (newEmails.length === 1) {
        // Remove the current recipient from the rec without email array
        removeRecFromNoEmailArray(stateHandler, newEmails[0].cart_id);
      }
      // Close the modal
      // callEmailRequiredModal(stateHandler, { dataArray: [], toCall: false });
    } catch (err: any) {
      prodLogInstance.error(err);
      await this.handleError(err, stateHandler, () => {
        this.handleOnlineAccessEmailUpdate(stateHandler, newEmails);
      });
    }
  }

  // Handle form submission for correction
  async handleFormUpdate(correctedData: any, stateSetter: any, navigate: any) {
    try {
      DisableScreen.enable();
      const res = await updateForm(correctedData);
      devLogInstance.log("RESPONSE AFTER SUBMITTING THE CORRECTED FORM: ");
      devLogInstance.log(res);
      DisableScreen.disable();
      // Get cached review page type
      const reviewPageType = retrieveReviewPageType();
      // Navigate to the review page based on the cached type
      // reviewPageType && reviewPageType === REVIEW_PAGE_EFORM
      //   ? saveCartId(correctedData.cartId)
      //   : navigate(`${REVIEW}`);
      reviewPageType && reviewPageType === REVIEW_PAGE_EFORM
        ? navigate(`${REVIEW}?eform=true`)
        : navigate(`${REVIEW}`);
    } catch (err: any) {
      prodLogInstance.error(err);
      await this.handleError(err, stateSetter, () => {
        DisableScreen.disable();
        this.handleFormUpdate(correctedData, stateSetter, navigate);
      });
    }
  }

  async handleGettingVerificationCode(email: any, hashedPass: any, stateSetter: any) {
    DisableScreen.enable();
    try {
      const res = await getVerificationCode(email, hashedPass);
      devLogInstance.log(res);
      DisableScreen.disable();
      return true;
    } catch (err: any) {
      prodLogInstance.error(err.response);
      if (err.response.data.Error === "Incorrect password.") {
        await this.handleError(err, stateSetter, () => {
          DisableScreen.disable();
          this.handleGettingVerificationCode(email, hashedPass, stateSetter);
        });
      } else {
        return true;
      } // This else block is only for dev purpose, in production, there will be no else block
    }
  }

  async handleEmailChange(newEmail: any, verifyCode: any, stateSetter: any) {
    DisableScreen.enable();
    try {
      const res = await changeEmail(newEmail, verifyCode);
      devLogInstance.log(res);
      // Call a success popup
      const toastMessage = "Email updated successfully. Please Log Back in to continue.";
      handlePopup(toastMessage, SUCCESS_POPUP, stateSetter);
      setTimeout(() => {
        DisableScreen.disable();
        logoutUser(stateSetter); // Logout the user
      }, 2000); // Reload the page after 2s
    } catch (err: any) {
      prodLogInstance.error(err.response);
      // Call the error handler
      await this.handleError(err, stateSetter, () => {
        DisableScreen.disable();
        this.handleEmailChange(newEmail, verifyCode, stateSetter);
      });
    }
  }

  async handlePasswordChange(newPass: any, oldPass: any, stateSetter: any) {
    DisableScreen.enable();
    try {
      const res = await changePassword(newPass, oldPass);
      devLogInstance.log(res);
      // Call the popup with a success message and logout the user for re-login
      handlePopup(
        "Password changed successfully. Please Log back in to continue!",
        SUCCESS_POPUP,
        stateSetter
      );
      setTimeout(() => {
        DisableScreen.disable();
        logoutUser(stateSetter);
      }, 2000); // Logout the user after 2s
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, stateSetter, () => {
        DisableScreen.disable();
        this.handlePasswordChange(newPass, oldPass, stateSetter);
      });
    }
  }

  async handlePhonenumberChange(hashedPass: any, newPhonenum: any, stateSetter: any) {
    DisableScreen.enable();
    try {
      const res = await changePhonenum(hashedPass, newPhonenum);
      devLogInstance.log(res);
      // Update the cached phone number value
      const cachedUserDetails = getUserDetailsLocally();
      const updatedUserDetails = {
        ...cachedUserDetails,
        phonenumber: newPhonenum,
      };
      saveUserDetails(updatedUserDetails);
      // Call the popup with a success message
      handlePopup("Phone number updated successfully.", SUCCESS_POPUP, stateSetter);
      setTimeout(() => {
        DisableScreen.disable();
        window.location.reload();
      }, 2000); // Reload the page after 2s
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, stateSetter, () => {
        DisableScreen.disable();
        this.handlePhonenumberChange(hashedPass, newPhonenum, stateSetter);
      });
    }
  }

  async handleFullnameChange(newFullname: any, stateSetter: any) {
    DisableScreen.enable();
    try {
      const res = await changeFullname(newFullname);
      devLogInstance.log(res);
      // Update the cached full name value
      const cachedUserDetails = getUserDetailsLocally();
      const updatedUserDetails = {
        ...cachedUserDetails,
        full_name: newFullname,
      };
      saveUserDetails(updatedUserDetails);
      // Call the popup with a success message
      handlePopup("Name updated successfully.", SUCCESS_POPUP, stateSetter);
      setTimeout(() => {
        DisableScreen.disable();
        window.location.reload();
      }, 2000); // Reload the page after 2s
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, stateSetter, () => {
        DisableScreen.disable();
        this.handleFullnameChange(newFullname, stateSetter);
      });
    }
  }

  async handleFeedbackSubmission(feedbackValues: any) {
    DisableScreen.enable();
    try {
      const res = await submitFeedback(feedbackValues);
      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleFeedbackSubmission(feedbackValues);
      });
    }
  }

  // Handle getting all history data
  async handleGettingAllHistoryData(year = calCurrentTaxYear()) {
    try {
      const res = await getAllHistoryData(year);
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        this.handleGettingAllHistoryData(year);
        window.location.reload();
      });
    }
  }

  // Handle getting payer specific history data
  async handleGettingSpecificHistoryData(payerId = null, year = calCurrentTaxYear()) {
    try {
      const res = await getSpecificHistoryData(payerId, year);
      return res;
    } catch (error) {
      prodLogInstance.error(error);
      await this.handleError(error, Main.stateDispatch, () => {
        this.handleGettingSpecificHistoryData(payerId, year);
      });
    }
  }

  // Handle adding new admin
  async handleAddingAdmin(newAdminData: NewAdminData) {
    DisableScreen.enable();
    try {
      const res = await addAdmin(newAdminData);
      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleAddingAdmin(newAdminData);
      });
    }
  }

  // Handle deleting an existing admin
  async handleDeletingAdmin(adminEmail: string) {
    DisableScreen.enable();
    try {
      const res = await deleteAdmin(adminEmail);
      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleDeletingAdmin(adminEmail);
      });
    }
  }

  // Handle getting admin prices
  async handleGettingAdminPrices() {
    DisableScreen.enable();
    try {
      const res = await getAdminPrices();
      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleGettingAdminPrices();
      });
    }
  }

  // Handle editing admin prices
  async handleEditingAdminPrices(data: AdminPriceData) {
    DisableScreen.enable();
    try {
      const res = await editAdminPrice(data);
      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleEditingAdminPrices(data);
      });
    }
  }

  // Handle getting admin coupons
  async handleGettingAdminCoupons() {
    DisableScreen.enable();
    try {
      const res = await getAdminCoupons();
      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleGettingAdminCoupons();
      });
    }
  }

  // Handle editing admin prices
  async handleAddingCoupons(data: AdminCouponData) {
    DisableScreen.enable();
    try {
      const res = await addCoupon(data);
      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleAddingCoupons(data);
      });
    }
  }

  // Handle deleting coupon
  async handleDeletingCoupon(couponid: string) {
    DisableScreen.enable();
    try {
      const res = await deleteCoupon(couponid);
      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleDeletingCoupon(couponid);
      });
    }
  }

  // Handle getting admins for viewing
  async handleGettingAdmins(): Promise<ViewAdminsData[] | undefined> {
    DisableScreen.enable();
    try {
      const { adminData } = await getAdmins();
      DisableScreen.disable();
      return adminData;
    } catch (err) {
      prodLogInstance.error(err);
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
    }
  }

  // Handle changing admin password
  async handleChangeAdminPassword(data: PasswordChangeData) {
    DisableScreen.enable();
    try {
      const res = await changeAdminPassword(data);
      devLogInstance.log(res);
      DisableScreen.disable();
      // Display a success popup
      handlePopup("Password changed successfully!", SUCCESS_POPUP, Main.stateDispatch);
      // Reload the page
      window.location.reload();
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
    }
  }

  // Handle downloading ASCII file from admin panel
  async handleAsciiFileDownload(year: number) {
    DisableScreen.enable();
    try {
      const [blobData, headers] = await getAsciiFile(year);
      DisableScreen.disable();

      if (!headers["content-disposition"]) {
        // Display toast message saying no pending forms
        handlePopup(noAsciiFileMsg, SUCCESS_POPUP, Main.stateDispatch);
        return;
      }

      const filename = getTheFileName(headers["content-disposition"]);
      devLogInstance.log(filename);

      Download.downloadbyBlob(blobData, filename);
    } catch (err) {
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
      throw err;
    }
  }

  // Handle setting transmisison status from admin panel
  async handleSettingTransmissionStatus(data_: SetTransmissionStatusBody) {
    DisableScreen.enable();
    try {
      const res = await setTransmissionStatus(data_);
      DisableScreen.disable();

      handlePopup(res.message ?? "Success", SUCCESS_POPUP, Main.stateDispatch);
    } catch (err) {
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
      throw err;
    }
  }
  // Handle download  transmisison pending form from admin panel
  async handleDownloadTransmissionPendingForm(status:string) {
    DisableScreen.enable();
    try {
      const res = await downloadDocument(status);
      // Create a new Blob URL
      const url = window.URL.createObjectURL(res);

      // Open the Blob URL in a new tab
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank"; // Open in new tab
      a.download = ""; // Optional: Set the file name here
      document.body.appendChild(a);
      a.click();
      a.remove(); // Remove the anchor element

      // Optionally revoke the Blob URL after a delay
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
      DisableScreen.disable();
      // handlePopup(res.message ?? "Success", SUCCESS_POPUP, Main.stateDispatch);
    } catch (err) {
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
      throw err;
    }
  }

  // Handle sending contact us data
  async handleSendingContactUsData(data: ContactUsData) {
    DisableScreen.enable();
    try {
      const res = await contactUs(data);
      devLogInstance.log(res);
      DisableScreen.disable();
      handlePopup(
        "Thank your for submitting your query, we will get back to you as soon as possible!",
        SUCCESS_POPUP,
        Main.stateDispatch
      );
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
    }
  }

  // Handle sending verification code for password reset
  async handleSendingResetVerificationCode(email: string, accountType: string) {
    DisableScreen.enable();
    try {
      let res;

      switch (accountType) {
        case USER_ACCOUNT:
          res = await sendPasswordResetVerifyCode(email);
          break;

        case ADMIN_ACCOUNT:
          res = await axiosInstance.post("/admin/send_verification_code", { email });
          break;

        default:
          throw new Error("Account type not supported yet!");
      }

      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
      throw err;
    }
  }

  // Handle resetting password
  async handlePasswordReset(data: PasswordResetData, accountType: string) {
    DisableScreen.enable();
    try {
      let res;

      switch (accountType) {
        case USER_ACCOUNT:
          res = await resetPassword(data);
          break;

        case ADMIN_ACCOUNT:
          res = await axiosInstance.put("/admin/reset_password", data);
          break;

        default:
          throw new Error("Account type not supported yet!");
      }

      DisableScreen.disable();
      return res;
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
      throw err;
    }
  }

  async handleRecipientLogin(
    loginData: OnlineAccessLoginData,
    fid: string,
    oakey: string,
    navigate: NavigateFunction
  ) {
    DisableScreen.enable();
    try {
      const res: RecipientLoginResponse = await recipientLogin(loginData, fid, oakey);
      DisableScreen.disable();

      devLogInstance.log("Recipient login backend response:");
      devLogInstance.log(res);

      // Log in the recipient
      loginRecipient(res.recipientAccessToken, res.consent, fid, Main.stateDispatch, navigate);
    } catch (err) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
      throw err;
    }
  }

  // Handle getting consent page data
  async handleGettingConsentPageData() {
    DisableScreen.enable();
    try {
      const res: ConsentDataResponse = await getConsentPageData();
      DisableScreen.disable();
      return res;
    } catch (err) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
      throw err;
    }
  }

  // Handle submitting consent page data
  async handleSubmittingConsentData(imgFile: File, consent: boolean) {
    DisableScreen.enable();
    try {
      await submitConsentData(imgFile, consent);
      DisableScreen.disable();
    } catch (err) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
      throw err;
    }
  }

  // Handle getting online access form data
  async handleGettingOnlineAcessFormData() {
    DisableScreen.enable();
    try {
      const res = await getOnlineAccessFormData();
      DisableScreen.disable();

      return res.revisedFormData;
    } catch (err) {
      prodLogInstance.error(err);
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        return;
      });
      throw err;
    }
  }

  // Handle getting payer names for correction
  async handleGettingPayerNames() {
    DisableScreen.enable();
    try {
      const res: { Message: string; payer_names: PayerData[] } = await getPayers();
      DisableScreen.disable();
      return res;
    } catch (err) {
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleGettingPayerNames();
      });
      throw err;
    }
  }

  // Handle getting form correction summary
  async handleGetFormSummaryForCorrection(payerId: string, year: string) {
    DisableScreen.enable();
    try {
      const res = await getFormCorrectionSummary(payerId, year);
      DisableScreen.disable();

      return res;
    } catch (err) {
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleGetFormSummaryForCorrection(payerId, year);
      });
      throw err;
    }
  }

  // Handle getting form correction data
  async handleGettingFormCorrectionData(formId: string) {
    try {
      const res = await getFormCorrectionData(formId);
      DisableScreen.disable();

      return [res.form_data, res.history_id];
    } catch (err) {
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleGettingFormCorrectionData(formId);
      });
      throw err;
    }
  }

  // Handle corrected form submission
  async handleCorrectedFormSubmission(correctedData: any) {
    DisableScreen.enable();
    try {
      devLogInstance.log("Submitting the corrected form...");
      const res = await submitCorrectedData(correctedData);
      DisableScreen.disable();

      devLogInstance.log("Response: ");
      devLogInstance.log(res);
    } catch (err) {
      prodLogInstance.error(err);
      await this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleCorrectedFormSubmission(correctedData);
      });
    }
  }

  async activateEmail(
    userId: string,
    userToken: string
  ): Promise<{ is_activated: boolean; status: number }> {
    try {
      const res: { data: { is_activated: boolean }; status: number } = await activateEmail(
        userId,
        userToken
      );
      devLogInstance.log("Activation status from backend:");
      devLogInstance.log(res);
      return { is_activated: res.data.is_activated, status: res.status };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // Handle submitting enquiry form
  async handleEnquirySubmission(
    data: EnquiryData,
    showAcknlgmnt: React.Dispatch<React.SetStateAction<boolean>> | null,
    fromFloater?: boolean
  ) {
    try {
      if (!fromFloater) {
        // Validate the data
        const invalidFields = formFieldsValidator.validateEnquiryData(data);
        devLogInstance.error("Invalid fields:");
        devLogInstance.error(invalidFields);

        if (invalidFields.length > 0) throw invalidFields;
      }

      // Submit the data
      const data_: ContactUsData = {
        full_name: data.name,
        phone: data.phone,
        email: data.email,
        body: data.query,
        company_name: "",
        subject_type: !fromFloater ? "Enquiry" : "Enquiry from floater after login",
      };
      DisableScreen.enable();
      const res = await contactUs(data_);
      DisableScreen.disable();

      devLogInstance.log("Response after submitting enquiry data:");
      devLogInstance.log(res);

      if (!fromFloater) {
        // Show acknowlegment message
        showAcknlgmnt && showAcknlgmnt(true);
      } else {
        // Show a popup
        handlePopup(enquiryFloaterSuccessMessage, SUCCESS_POPUP, Main.stateDispatch);
      }
    } catch (error: any) {
      DisableScreen.disable();
      if (error.response) {
        this.handleError(error, Main.stateDispatch, () => {
          // Blank
        });
      } else throw error;
    }
  }

  // Enterprise system related
  async handleGettingStaff() {
    try {
      DisableScreen.enable();
      const { data } = await axiosInstance.get("/enterprise/non_staff_operation/get_staff");
      devLogInstance.log("Response after getting staff");
      devLogInstance.log(data);

      DisableScreen.disable();

      return data;
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleGettingStaff();
      });
    }
  }
  // Handle getting transaction details
  async handleGettingTransacDetails({ year, month }: { year: number; month: string }) {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.post("/admin/get_transaction_details", {
        year,
        month,
      });
      devLogInstance.log("Response after getting transaction details");
      devLogInstance.log(data);
      DisableScreen.disable();
      return data;
    } catch (err: any) {
      await this.handleError(err, Main.stateDispatch, () => {});
      DisableScreen.disable();
      throw err;
    }
  }

  async handleAddingStaff(staffData: {
    staff_email: string;
    staff_name: string;
    staff_phone: string;
  }) {
    try {
      DisableScreen.enable();
      const { data } = await axiosInstance.post(
        "/enterprise/non_staff_operation/add_staff",
        staffData
      );
      DisableScreen.disable();
      devLogInstance.log({ response_after_adding_staff: data });

      handlePopup("Member added and invite sent!", SUCCESS_POPUP, Main.stateDispatch);
    } catch (err) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleAddingStaff(staffData);
      });
    }
  }

  // Handle assigning payers to a staff
  async handleAssigningPayers(staffIdOrGroupId: string, payerIds: string[], isStaff: boolean) {
    try {
      DisableScreen.enable();

      if (isStaff) {
        const { data } = await axiosInstance.post(
          "/enterprise/non_staff_operation/add_payers_to_staff",
          {
            staff_id: staffIdOrGroupId,
            payer_ids: payerIds,
          }
        );
        devLogInstance.log({ response_after_assigning_payers_to_staff: data });
      } else {
        // Assign payers to group
        const { data } = await axiosInstance.post(
          "/enterprise/non_staff_operation/add_payers_to_group",
          {
            group_id: staffIdOrGroupId,
            payer_ids: payerIds,
          }
        );
        devLogInstance.log({ response_after_assigning_payers_to_group: data });
      }

      DisableScreen.disable();

      // Display a success toast message
      handlePopup("All selected payers have been assigned", SUCCESS_POPUP, Main.stateDispatch);
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleAssigningPayers(staffIdOrGroupId, payerIds, isStaff);
      });
    }
  }

  // Handle unassigning payers from a staff
  async handleUnassigningPayers(staffIdOrGroupId: string, payerIds: string[], isStaff: boolean) {
    try {
      DisableScreen.enable();

      if (isStaff) {
        const { data } = await axiosInstance.put(
          "/enterprise/non_staff_operation/remove_payer_from_staff",
          {
            staff_id: staffIdOrGroupId,
            payer_ids: payerIds,
          }
        );
        devLogInstance.log({ response_after_unassigning_payers_from_staff: data });
      } else {
        // remove payers from group
        const { data } = await axiosInstance.put(
          "/enterprise/non_staff_operation/remove_payer_from_group",
          {
            group_id: staffIdOrGroupId,
            payer_ids: payerIds,
          }
        );
        devLogInstance.log({ response_after_unassigning_payers_from_group: data });
      }

      DisableScreen.disable();

      // Display a success toast message
      handlePopup("All selected payers have been un-assigned", SUCCESS_POPUP, Main.stateDispatch);
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleUnassigningPayers(staffIdOrGroupId, payerIds, isStaff);
      });
    }
  }

  // Handle removing staff
  async handleRemovingStaff(
    staffIds: string[],
    updateStaffRecords: React.Dispatch<React.SetStateAction<StaffRecord[]>>
  ) {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.delete("/enterprise/non_staff_operation/delete_staff", {
        data: {
          staff_ids: staffIds,
        },
      });

      DisableScreen.disable();

      devLogInstance.log({ response_after_removing_staff: data });

      // Display a success toast message
      handlePopup(
        "Member(s) and all related data have been removed successfully!",
        SUCCESS_POPUP,
        Main.stateDispatch
      );

      getStaff(updateStaffRecords);
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleRemovingStaff(staffIds, updateStaffRecords);
      });
    }
  }

  // Handle getting groups for single staff
  async handleGettingGroupsForSingleStaff(staffId: string) {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.get(
        "/enterprise/non_staff_operation/get_groups_for_single_staff",
        {
          headers: {
            StaffId: staffId,
          },
        }
      );

      DisableScreen.disable();

      devLogInstance.log({ response_after_getting_groups_for_single_staff: data });

      return data.groups;
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleGettingGroupsForSingleStaff(staffId);
      });
    }
  }

  // Handle creating group
  async handleCreatingGroup(groupData: { group_name: string; group_description: string }) {
    try {
      DisableScreen.enable();
      const { data } = await axiosInstance.post(
        "/enterprise/non_staff_operation/create_group",
        groupData
      );
      DisableScreen.disable();
      devLogInstance.log({ response_after_creating_group: data });

      handlePopup("Group created successfully!", SUCCESS_POPUP, Main.stateDispatch);
    } catch (err) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleCreatingGroup(groupData);
      });
    }
  }

  // Handle getting groups created by the current user
  async handleGettingGroups() {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.get("/enterprise/non_staff_operation/get_group");

      DisableScreen.disable();

      devLogInstance.log({ response_after_getting_users_groups: data });

      return data;
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleGettingGroups();
      });
    }
  }

  // Handle removing group
  async handleRemovingGroup(
    groupIds: string[],
    updateGroupRecords: React.Dispatch<React.SetStateAction<GroupRecord[]>>
  ) {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.delete("/enterprise/non_staff_operation/delete_group", {
        data: {
          group_ids: groupIds,
        },
      });

      DisableScreen.disable();

      devLogInstance.log({ response_after_removing_group: data });

      // Display a success toast message
      handlePopup(
        "Group(s) and all related data have been removed successfully!",
        SUCCESS_POPUP,
        Main.stateDispatch
      );

      getGroup(updateGroupRecords);
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleRemovingGroup(groupIds, updateGroupRecords);
      });
    }
  }

  // Handle adding staffs to a group
  async handleAddingStaffsToGroup(
    groupId: string,
    staffIds: string[],
    updateGroupRecords: React.Dispatch<React.SetStateAction<GroupRecord[]>>
  ) {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.post(
        "/enterprise/non_staff_operation/add_staffs_to_group",
        {
          group_id: groupId,
          staff_ids: staffIds,
        }
      );

      DisableScreen.disable();

      devLogInstance.log({ RESPONSE_AFTER_ADDING_STAFFS_TO_GROUP: data });

      handlePopup("Member(s) added successfully", SUCCESS_POPUP, Main.stateDispatch);

      getGroup(updateGroupRecords); // Refetch the latest Group records
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleAddingStaffsToGroup(groupId, staffIds, updateGroupRecords);
      });
    }
  }

  // Handle removing staffs from a group
  async handleRemovingStaffsFromGroup(
    groupId: string,
    staffIds: string[],
    updateGroupRecords: React.Dispatch<React.SetStateAction<GroupRecord[]>>
  ) {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.put(
        "/enterprise/non_staff_operation/remove_staff_from_group",
        {
          group_id: groupId,
          staff_ids: staffIds,
        }
      );

      DisableScreen.disable();

      devLogInstance.log({ RESPONSE_AFTER_REMOVING_STAFFS_FROM_GROUP: data });

      handlePopup("Member(s) unassigned successfully", SUCCESS_POPUP, Main.stateDispatch);

      getGroup(updateGroupRecords); // Refetch the latest Group records
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleRemovingStaffsFromGroup(groupId, staffIds, updateGroupRecords);
      });
    }
  }

  // Handle getting account details
  async handleGettingAccountDetails() {
    try {
      Main.stateDispatch({
        type: TO_START_LOADER,
        payload: true,
      });

      const { data } = await axiosInstance.get("/enterprise/get_account_details");

      Main.stateDispatch({
        type: TO_START_LOADER,
        payload: false,
      });

      devLogInstance.log({ RESPONSE_AFTER_GETTING_ACCOUNT_DETAILS: data });

      return data;
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        Main.stateDispatch({
          type: TO_START_LOADER,
          payload: false,
        });
        this.handleGettingAccountDetails();
      });
      await this.handleError(err, Main.stateDispatch, () => {});
      DisableScreen.disable();
      throw err;
    }
  }

  // Handle switching to main account
  async handleSwitchingToMain(navigate: NavigateFunction) {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.get("/enterprise/switch_to_main");

      DisableScreen.disable();

      devLogInstance.log({
        MAIN_ACCESS_AND_REFRESH_TOKENS: {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
        },
      });

      // Display a success popup
      handlePopup("Switched to main account", SUCCESS_POPUP, Main.stateDispatch);

      const existingUserDetails = getUserDetailsLocally();
      // Replace the full name value of the user details
      // with the username in the response and save it
      saveUserDetails({ ...existingUserDetails, full_name: data.username });

      // Replace the access and refresh token with the new tokens
      saveTokens(data.access_token, data.refresh_token);

      // Remove the isStaff delimeter
      sessionStorage.removeItem("isStaff");

      // navigate to home page
      navigate(HOME);
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleSwitchingToMain(navigate);
      });
    }
  }

  // Handle switching to member account
  async handleSwitchingToMember(staffId: string, staffName: string, navigate: NavigateFunction) {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.post("/enterprise/switch_to_staff", {
        staff_id: staffId,
      });

      DisableScreen.disable();

      devLogInstance.log({
        STAFF_ACCESS_AND_REFRESH_TOKENS: {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
        },
      });

      // Display a success popup
      handlePopup("Switched to member account", SUCCESS_POPUP, Main.stateDispatch);

      const existingUserDetails = getUserDetailsLocally();
      // Replace the full name value of the user details
      // with staff name and save it
      saveUserDetails({ ...existingUserDetails, full_name: staffName });

      // Replace the access and refresh token with the new tokens
      saveTokens(data.access_token, data.refresh_token);

      // In the session storage save a delimeter stating
      // this account is a staff account
      sessionStorage.setItem("isStaff", "true");

      // navigate to home page
      navigate(HOME);
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleSwitchingToMember(staffId, staffName, navigate);
      });
    }
  }

  // Handle accepting member invitation
  async handleInvitationAccept(staffId: string) {
    try {
      DisableScreen.enable();

      const { data } = await axiosInstance.post(
        "/enterprise/non_staff_operation/staff_accept_invite",
        {
          staff_id: staffId,
        }
      );

      DisableScreen.disable();

      devLogInstance.log({ RESPONSE_AFTER_ACCEPTING_INVITE: data });

      // Reload the current page
      window.location.reload(); // To refetch all the required data and update the state
    } catch (err: any) {
      this.handleError(err, Main.stateDispatch, () => {
        DisableScreen.disable();
        this.handleInvitationAccept(staffId);
      });
    }
  }

  async handleError(err: any, stateHandler: any, callBack: any) {
    // prodLogInstance.error(err);
    const path: string = err.request.responseURL;
    const isAdminRoute = path.includes("/admin");
    const isOnlineAccessRoute =
      path.includes("consent_page_data") || path.includes("online_access_form_data");

    if (
      !isAdminRoute &&
      !isOnlineAccessRoute &&
      err.response &&
      err.response.status === 401 &&
      this.authErrorFlag <= 3
    ) {
      prodLogInstance.error(`Error status --> ${err.response.status}`);
      devLogInstance.log("Incrementing the 401 error flag by 1");
      this.authErrorFlag += 1;

      const refresh_token = retrieveToken("refresh_token");
      devLogInstance.log(refresh_token);

      if (refresh_token) {
        // Get new tokens
        try {
          const newTokens = await getRefreshToken(refresh_token);
          devLogInstance.log("New Access and Refresh token");
          devLogInstance.log(newTokens);
          saveTokens(newTokens.accessToken, newTokens.refreshToken);
          callBack();
        } catch (err) {
          prodLogInstance.error(err);
          // Stop the loader
          stateHandler({
            type: TO_START_LOADER,
            payload: false,
          });
          DisableScreen.disable();
          // Logout the user
          logoutUser(stateHandler);
        }
      } else {
        DisableScreen.disable();
        // Logout the user
        logoutUser(stateHandler);
      }
    } else if (isAdminRoute && err.response && err.response.status === 401) {
      DisableScreen.disable();
      // Logout the admin since there is no refresh token to get new set of tokens
      logoutAdmin(stateHandler);
    } else if (isOnlineAccessRoute && err.response && err.response.status === 401) {
      DisableScreen.disable();
      // Logout the recipient since there is no refresh token to get new set of tokens
      logoutRecipient(stateHandler);
    } else if (err.response && (err.response.status === 400 || err.response.status === 500)) {
      DisableScreen.disable();
      const toastMessage =
        err.response.data.Error ?? "There was an unexpected error! Please try after sometime.";
      devLogInstance.log(toastMessage);
      handlePopup(toastMessage, ERROR_POPUP, stateHandler);
    } else {
      DisableScreen.disable();
      logger.error("Network error / Server error");
      // Stop the loader
      stateHandler({
        type: TO_START_LOADER,
        payload: false,
      });
      // Show a error pop up
      handlePopup(serverErrorMsg, ERROR_POPUP, stateHandler);

      devLogInstance.log("Reverting back to the default value of 401 error flag...");
      this.authErrorFlag = 0; // Revert to the default value
    }
  }
}

const Handler = new Main();

export default Handler;
