import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ADMIN } from "../../lib/routes";
import { FaHome } from "react-icons/fa";
import { useTicketSocket } from "../../lib/customHooks";
import { IoTicket } from "react-icons/io5";
import TicketNotificationContainer from "./SupportTicketRelated/TicketNotificationContainer";
import logoutAdmin from "../../lib/AdminRelatedUtils/logoutAdmin";
import { useDispatch } from "react-redux";
import { FiLogOut } from "react-icons/fi";

export default function AdminNavbar() {

  //Commented out this line for socket issue 
  // const { newTickets } = useTicketSocket();

  const [newTicketCount, setNewTicketCount] = useState(0);

  const [openTicketNotificationContainer, toOpenTicketNotificationContainer] = useState(false);

  const [ticketsAcknowledged, setAcknowledgedTickets] = useState<string[]>([]);
  console.log({ ticketsAcknowledged });

  const dispatch = useDispatch();

  // Handle whenever a ticket detail component is hovered inside the notification area
  const handleMouseOverTicketDetail = (ticketId: string) => {
    if (!ticketsAcknowledged.includes(ticketId))
      setAcknowledgedTickets([...ticketsAcknowledged, ticketId]);
  };

    //  {
    //    /* Commentted Out this use effect  for Socket Issue  */
    //  }
  // // Set the ticket count
  // useEffect(() => {
  //   setNewTicketCount(newTickets.length - ticketsAcknowledged.length);

  //   return () => setNewTicketCount(0);
  // }, [newTickets, ticketsAcknowledged]);

  // Handle click outside the ticket notification container
  useEffect(() => {
    const handleClick = (e: any) => {
      console.log(e.target.id);
      const id = e.target.id || e.target.parentElement.id;

      if (id && id === "ticket-notification-icon")
        toOpenTicketNotificationContainer((prevState) => !prevState); // When clicked on the icon
      // when clicked elsewehere
      else if (id && id.includes("ticket-notification")) toOpenTicketNotificationContainer(true);
      else toOpenTicketNotificationContainer(false);
    };

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, []);

  return (
    <div className="bg-[#151515] text-white border-b flex flex-col justify-between w-[4%] h-full py-6">
      <div className="flex flex-col items-center gap-10">
        <Link to={ADMIN}>
          <FaHome className="text-2xl" />
        </Link>
        {/* <Link
          to={ADMIN_PASSWORD_CHANGE}
          className="text-taxeve-primary-blue font-bold underline underline-offset-4 text-lg"
        >
          Change Password
        </Link> */}


          {/* Commentted Out for Socket Issue  */}
        {/* <div className="relative cursor-pointer">
          {newTicketCount > 0 && (
            <span className="absolute -top-1 -right-4 rounded-full bg-red-500 w-[1.1rem] h-[1.1rem] flex items-center justify-center font-bold text-white">
              {newTicketCount > 99 ? "99+" : newTicketCount}
            </span>
          )}
          <IoTicket id="ticket-notification-icon" className="text-2xl" />
          {openTicketNotificationContainer && (
            <div
              id="ticket-notification-container"
              className="absolute top-4 left-7 z-10 py-2 bg-white text-black shadow-lg rounded-lg w-[27rem] max-h-[35rem] overflow-auto"
            >
              {newTickets.length <= 0 && (
                <h3 className="text-center text-gray-400">No new tickets has been assigned</h3>
              )}

              {newTickets.length > 0 &&
                newTickets.map((ticketObj, idx) => (
                  <TicketNotificationContainer
                    key={ticketObj.ticketId}
                    ticketObj={ticketObj}
                    isNew={!ticketsAcknowledged.includes(ticketObj.ticketId)}
                    className={
                      idx !== newTickets.length - 1 // Not the last item
                        ? "border-b border-gray-300"
                        : ""
                    }
                    handleMouseOver={handleMouseOverTicketDetail}
                  />
                ))}
            </div>
          )}
        </div> */}
      </div>
      <footer>
        <button
          className="admin-logout hover:opacity-75 text-white w-full flex items-center justify-center"
          onClick={() => logoutAdmin(dispatch)}
        >
          <FiLogOut className="text-2xl" />
        </button>
      </footer>
    </div>
  );
}
