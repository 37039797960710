import { axiosInstance } from "../axiosConfig";

// Function to handle document download
export default async function downloadDocument(status: string): Promise<Blob> {
  try {
    const response = await axiosInstance.get(`/admin/transmission-pending-data`, {
      responseType: "blob", // Expect binary data
      params: { status },
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/octet-stream",
      },
    });

    return response.data; // Return the Blob data
  } catch (err) {
    throw err; // Propagate the error
  }
}
